* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background-color: #FBFBF9;
}

/* FONTS */
/* Nunito */
@font-face {
  font-family: "nunito-extralight";
  src: url("./assets/fonts/nunito/nunito-extralight-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/nunito/nunito-extralight-webfont.woff") format("woff"),
    url("./assets/fonts/nunito/Nunito-ExtraLight.ttf") format("ttf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "nunito-extralightitalic";
  src: url("./assets/fonts/nunito/nunito-extralightitalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/nunito/nunito-extralightitalic-webfont.woff")
      format("woff"),
    url("./assets/fonts/nunito/Nunito-ExtraLightItalic.ttf") format("ttf");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "nunito-light";
  src: url("./assets/fonts/nunito/nunito-light-webfont.woff2") format("woff2"),
    url("./assets/fonts/nunito/nunito-light-webfont.woff") format("woff"),
    url("./assets/fonts/nunito/Nunito-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "nunito-lightitalic";
  src: url("./assets/fonts/nunito/nunito-lightitalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/nunito/nunito-lightitalic-webfont.woff") format("woff"),
    url("./assets/fonts/nunito/Nunito-LightItalic.ttf") format("ttf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "nunito-regular";
  src: url("./assets/fonts/nunito/nunito-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/nunito/nunito-regular-webfont.woff") format("woff"),
    url("./assets/fonts/nunito/Nunito-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "nunito-italic";
  src: url("./assets/fonts/nunito/nunito-italic-webfont.woff2") format("woff2"),
    url("./assets/fonts/nunito/nunito-italic-webfont.woff") format("woff"),
    url("./assets/fonts/nunito/Nunito-Italic.ttf") format("ttf");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "nunito-medium";
  src: url("./assets/fonts/nunito/nunito-medium-webfont.woff2") format("woff2"),
    url("./assets/fonts/nunito/nunito-medium-webfont.woff") format("woff"),
    url("./assets/fonts/nunito/Nunito-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "nunito-semibold";
  src: url("./assets/fonts/nunito/nunito-semibold-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/nunito/nunito-semibold-webfont.woff") format("woff"),
    url("./assets/fonts/nunito/Nunito-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "nunito-semibolditalic";
  src: url("./assets/fonts/nunito/nunito-semibolditalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/nunito/nunito-semibolditalic-webfont.woff")
      format("woff"),
    url("./assets/fonts/nunito/Nunito-SemiBoldItalic.ttf") format("ttf");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "nunito-bold";
  src: url("./assets/fonts/nunito/nunito-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/nunito/nunito-bold-webfont.woff") format("woff"),
    url("./assets/fonts/nunito/Nunito-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "nunito-bolditalic";
  src: url("./assets/fonts/nunito/nunito-bolditalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/nunito/nunito-bolditalic-webfont.woff") format("woff"),
    url("./assets/fonts/nunito/Nunito-BoldItalic.ttf") format("ttf");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "nunito-extrabold";
  src: url("./assets/fonts/nunito/nunito-extrabold-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/nunito/nunito-extrabold-webfont.woff") format("woff"),
    url("./assets/fonts/nunito/Nunito-ExtraBold.ttf") format("ttf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "nunito-extrabolditalic";
  src: url("./assets/fonts/nunito/nunito-extrabolditalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/nunito/nunito-extrabolditalic-webfont.woff")
      format("woff"),
    url("./assets/fonts/nunito/Nunito-ExtraBoldItalic.ttf") format("ttf");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "nunito-black";
  src: url("./assets/fonts/nunito/nunito-black-webfont.woff2") format("woff2"),
    url("./assets/fonts/nunito/nunito-black-webfont.woff") format("woff"),
    url("./assets/fonts/nunito/Nunito-Black.ttf") format("ttf");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "nunito-blackitalic";
  src: url("./assets/fonts/nunito/nunito-blackitalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/nunito/nunito-blackitalic-webfont.woff") format("woff"),
    url("./assets/fonts/nunito/Nunito-BlackItalic.ttf") format("ttf");
  font-weight: 900;
  font-style: italic;
}
/* Montserrat */
@font-face {
  font-family: "montserrat-extralight";
  src: url("./assets/fonts/montserrat/montserrat-extralight-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-extralight-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-ExtraLight.otf") format("otf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "montserrat-extralightitalic";
  src: url("./assets/fonts/montserrat/montserrat-extralightitalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-extralightitalic-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-ExtraLightItalic.otf")
      format("otf");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "montserrat-light";
  src: url("./assets/fonts/montserrat/montserrat-light-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-light-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-Light.otf") format("otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "montserrat-lightitalic";
  src: url("./assets/fonts/montserrat/montserrat-lightitalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-lightitalic-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-LightItalic.otf") format("otf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "montserrat-regular";
  src: url("./assets/fonts/montserrat/montserrat-regular-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-regular-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-Regular.otf") format("otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "montserrat-italic";
  src: url("./assets/fonts/montserrat/montserrat-italic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-italic-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-Italic.otf") format("otf");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "montserrat-medium";
  src: url("./assets/fonts/montserrat/montserrat-medium-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-medium-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-Medium.otf") format("otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "montserrat-mediumitalic";
  src: url("./assets/fonts/montserrat/montserrat-mediumitalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-mediumitalic-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-MediumItalic.otf") format("otf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "montserrat-semibold";
  src: url("./assets/fonts/montserrat/montserrat-semibold-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-semibold-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-SemiBold.otf") format("otf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "montserrat-semibolditalic";
  src: url("./assets/fonts/montserrat/montserrat-semibolditalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-semibolditalic-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-SemiBoldItalic.otf") format("otf");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "montserrat-bold";
  src: url("./assets/fonts/montserrat/montserrat-bold-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-bold-webfont.woff") format("woff"),
    url("./assets/fonts/montserrat/Montserrat-Bold.otf") format("otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "montserrat-bolditalic";
  src: url("./assets/fonts/montserrat/montserrat-bolditalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-bolditalic-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-BoldItalic.otf") format("otf");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "montserrat-extrabold";
  src: url("./assets/fonts/montserrat/montserrat-extrabold-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-extrabold-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-ExtraBold.otf") format("otf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "montserrat-extrabolditalic";
  src: url("./assets/fonts/montserrat/montserrat-extrabolditalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-extrabolditalic-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-ExtraBoldItalic.otf")
      format("otf");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "montserrat-black";
  src: url("./assets/fonts/montserrat/montserrat-black-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-black-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-Black.otf") format("otf");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "montserrat-blackitalic";
  src: url("./assets/fonts/montserrat/montserrat-blackitalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/montserrat/montserrat-blackitalic-webfont.woff")
      format("woff"),
    url("./assets/fonts/montserrat/Montserrat-BlackItalic.otf") format("otf");
  font-weight: 900;
  font-style: italic;
}

/* Animations */
@keyframes breatheCircleSmall {
  from {
    width: 0.7rem;
    height: 0.7rem;
  }
  to {
    width: 1.4rem;
    height: 1.4rem;
  }
}

@keyframes breatheCircleMedium {
  from {
    width: 0.8rem;
    height: 0.8rem;
  }
  to {
    width: 1.6rem;
    height: 1.6rem;
  }
}
@keyframes breatheCircleBig {
  from {
    width: 0.9rem;
    height: 0.9rem;
  }
  to {
    width: 1.8rem;
    height: 1.8rem;
  }
}

@keyframes borderRadiusHero {
  0% {
    border-top-left-radius: 150px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 150px;
    border-bottom-left-radius: 50px;
  }
  100% {
    border-top-left-radius: 50px;
    border-top-right-radius: 150px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 100px;
  }
}

@keyframes borderRadiusSocials {
  0% {
    border-top-left-radius: 25%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  33% {
    border-top-left-radius: 50%;
    border-top-right-radius: 25%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  66% {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 25%;
    border-bottom-left-radius: 50%;
  }
  100% {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 25%;
  }
}

@keyframes borderSquareCircle {
  0% {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  100% {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
}

@keyframes borderRadiusTitle {
  0% {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 5px;
  }
  100% {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 20px;
  }
}

@keyframes borderRadiusTitleAllSidesSmall {
  0% {
    border-top-left-radius: O;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
  100% {
    border-top-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 0;
  }
}

@keyframes borderRadiusTitleAllSides {
  0% {
    border-top-left-radius: 5px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 10px;
  }
  100% {
    border-top-left-radius: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 5px;
  }
}

@keyframes animeCircleBorderColor {
  0% {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  25% {
    border-top-color: #fff;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  50% {
    border-top-color: #fff;
    border-right-color: #fff;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  70% {
    border-top-color: #fff;
    border-right-color: #fff;
    border-bottom-color: #fff;
    border-left-color: transparent;
  }
  100% {
    border-top-color: #fff;
    border-right-color: #fff;
    border-bottom-color: #fff;
    border-left-color: #fff;
  }
}
